import { createAsyncThunk } from "@reduxjs/toolkit";
import { http } from "../../services/http";

export const queryData = createAsyncThunk(
  "queryData",
  async (queryData: any, { rejectWithValue }) => {
    try {
      const encodedData = encodeURI(JSON.stringify(queryData));
      const { data } = await http.get(`api/search/?filter=${encodedData}`);

      return data;
    } catch (error: any) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
