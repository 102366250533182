// theme/index.js
import { extendTheme, withDefaultColorScheme } from "@chakra-ui/react";

// Global style overrides
import styles from "./globalStyles";

// Component style overrides
import { buttonTheme } from "./components/Button";
import { colors, space, fonts, fontSizes } from "./foundations";

export const verified = extendTheme(
  {
    styles,
    colors,
    fonts,
    //space, // hide for now; messes up all the other components big time
    fontSizes,
    components: {
      Button: buttonTheme,
    },
    config: {
      cssVarPrefix: "ve",
    },
  },
  withDefaultColorScheme({ colorScheme: "primary" })
);
