import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Select,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setFilterData } from "../../redux/reducers";
import {
  CompanyFilterMethod,
  EnvelopeFilterMethod,
  FilterEntity,
  FilterMethodBasedOnFilterEntity,
  UserFilterMethod,
} from "../../types";
import { filterActions } from "./searchStrategy";
import styles from "./styles.module.css";

interface FilterFormValues {
  filterEntity: FilterEntity;
  filterMethod: EnvelopeFilterMethod | CompanyFilterMethod | UserFilterMethod;
  filterValue: string;
}

const FilterMethodsBasedOnFilterEntity: React.FC<{
  filterEntity: FilterEntity;
}> = ({ filterEntity }) => {
  const filterMethods = FilterMethodBasedOnFilterEntity[filterEntity];
  return (
    <>
      {filterMethods.map((method) => (
        <option key={method} value={method}>
          {method}
        </option>
      ))}
    </>
  );
};

const filterFormValidationSchema = Yup.object().shape({
  filterEntity: Yup.mixed<FilterEntity>().oneOf(Object.values(FilterEntity)),
  filterMethod: Yup.string().required(),
  filterValue: Yup.string().required(),
});
export const SearchPage = () => {
  const dispatch = useAppDispatch();
  const filter = useAppSelector((state) => state.filter);

  const initialValues: FilterFormValues = {
    filterEntity: filter.filterEntity,
    filterMethod: filter.filterMethod,
    filterValue: filter.filterValue,
  };

  const onSubmit = (values: FilterFormValues) => {
    const actionToDispatch =
      filterActions[values.filterEntity][values.filterMethod];
    if (!actionToDispatch) {
      return;
    }
    dispatch(setFilterData(values));
    dispatch(actionToDispatch(values.filterValue));
  };

  const { handleSubmit, values, handleChange, setFieldValue, errors, touched } =
    useFormik<FilterFormValues>({
      validationSchema: filterFormValidationSchema,
      initialValues,
      onSubmit,
    });

  return (
    <form onSubmit={handleSubmit}>
      <Flex gap={"16px"} className={styles.container}>
        <FormControl
          isInvalid={!!errors.filterEntity && touched.filterEntity}
          maxWidth={"320px"}
        >
          <Select
            value={values.filterEntity}
            onChange={(event) => {
              setFieldValue("filterEntity", event.target.value);
              const firstItemInTheListOfMethodsForTheEntity =
                FilterMethodBasedOnFilterEntity[event.target.value][0];
              setFieldValue(
                "filterMethod",
                firstItemInTheListOfMethodsForTheEntity
              );
            }}
          >
            {Object.values(FilterEntity).map((status) => (
              <option key={status} value={status}>
                {status}
              </option>
            ))}
          </Select>
          {!!errors.filterEntity && touched.filterEntity && (
            <FormErrorMessage>{errors.filterEntity}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl
          isInvalid={!!errors.filterMethod && touched.filterMethod}
          maxWidth={"320px"}
        >
          <Select
            value={values.filterMethod}
            onChange={(event) => {
              setFieldValue("filterMethod", event.target.value);
            }}
          >
            <FilterMethodsBasedOnFilterEntity
              filterEntity={values.filterEntity}
            />
          </Select>
          {!!errors.filterMethod && touched.filterMethod && (
            <FormErrorMessage>{errors.filterMethod}</FormErrorMessage>
          )}
        </FormControl>

        <FormControl
          isInvalid={!!errors.filterValue && touched.filterValue}
          maxWidth={"320px"}
        >
          <Input
            data-testid="filter-value"
            role={"textbox"}
            name="filterValue"
            onChange={handleChange}
          />

          {!!errors.filterValue && touched.filterValue && (
            <FormErrorMessage role={"alert"} data-testid="filter-value-error">
              {errors.filterValue}
            </FormErrorMessage>
          )}
        </FormControl>

        <Button
          isDisabled={!values.filterValue}
          role={"button"}
          alignSelf={"flex-end"}
          type="submit"
          data-testid="submit-btn"
        >
          Search
        </Button>
      </Flex>
    </form>
  );
};
