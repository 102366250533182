// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".szpNuh\\+a8AQHnY6AWmI\\+8A\\=\\=::before {\n  display: inline-block;\n  content: \"\";\n  -webkit-border-radius: 0.375rem;\n  border-radius: 0.375rem;\n  height: 0.75rem;\n  width: 0.75rem;\n  margin-right: 0.5rem;\n  background-color: red;\n}\n\n.TD9ierRAbUf9NV89\\+uK2pQ\\=\\=::before {\n  display: inline-block;\n  content: \"\";\n  -webkit-border-radius: 0.375rem;\n  border-radius: 0.375rem;\n  height: 0.75rem;\n  width: 0.75rem;\n  margin-right: 0.5rem;\n  border: 1px solid gray;\n}\n\n.hGRdmH3S-97OhCe47PTDug\\=\\=::before {\n  display: inline-block;\n  content: \"\";\n  -webkit-border-radius: 0.375rem;\n  border-radius: 0.375rem;\n  height: 0.75rem;\n  width: 0.75rem;\n  margin-right: 0.5rem;\n  background-color: blue;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/resultsPage/envelopeResults/styles.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,WAAW;EACX,+BAA+B;EAC/B,uBAAuB;EACvB,eAAe;EACf,cAAc;EACd,oBAAoB;EACpB,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;EACrB,WAAW;EACX,+BAA+B;EAC/B,uBAAuB;EACvB,eAAe;EACf,cAAc;EACd,oBAAoB;EACpB,sBAAsB;AACxB;;AAEA;EACE,qBAAqB;EACrB,WAAW;EACX,+BAA+B;EAC/B,uBAAuB;EACvB,eAAe;EACf,cAAc;EACd,oBAAoB;EACpB,sBAAsB;AACxB","sourcesContent":[".rejected::before {\n  display: inline-block;\n  content: \"\";\n  -webkit-border-radius: 0.375rem;\n  border-radius: 0.375rem;\n  height: 0.75rem;\n  width: 0.75rem;\n  margin-right: 0.5rem;\n  background-color: red;\n}\n\n.pending::before {\n  display: inline-block;\n  content: \"\";\n  -webkit-border-radius: 0.375rem;\n  border-radius: 0.375rem;\n  height: 0.75rem;\n  width: 0.75rem;\n  margin-right: 0.5rem;\n  border: 1px solid gray;\n}\n\n.signed::before {\n  display: inline-block;\n  content: \"\";\n  -webkit-border-radius: 0.375rem;\n  border-radius: 0.375rem;\n  height: 0.75rem;\n  width: 0.75rem;\n  margin-right: 0.5rem;\n  background-color: blue;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rejected": "szpNuh+a8AQHnY6AWmI+8A==",
	"pending": "TD9ierRAbUf9NV89+uK2pQ==",
	"signed": "hGRdmH3S-97OhCe47PTDug=="
};
export default ___CSS_LOADER_EXPORT___;
