import { useAppSelector } from "../../redux/hooks";
import { FilterEntity } from "../../types";
import { ComapniesResultsPage } from "./companiesResults/ComapniesResultsPage";
import { EnvelopeResultsPage } from "./envelopeResults/EnvelopeResults";

export const ResultsPage = () => {
  const filter = useAppSelector((state) => state.filter);

  if (filter.filterEntity === FilterEntity.Envelope) {
    return <EnvelopeResultsPage />;
  }

  // if (filter.filterEntity === FilterEntity.Company) {
  //   return <ComapniesResultsPage />;
  // }

  return <></>;
};
