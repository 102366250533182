import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchData } from "../../utils/apiMock";
import companies from "../../mocks/companies.json";

export const getCompanies = createAsyncThunk(
  "getCompanies",
  async (envelopeId: any, { rejectWithValue }) => {
    try {
      const results = await fetchData<any>(companies);

      return results;
    } catch (error: any) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
