import { httpInstance } from "@verified/sspa-core-lib";

httpInstance.interceptors.request.use((config) => {
    if (config) {
      if (typeof config.headers!["x-namespace"] === "undefined") {
        config.headers!["x-namespace"] = `/*`;
      }
    }
  
    return config;
  });
export const http = httpInstance;
