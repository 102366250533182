import { ChakraProvider, Container } from "@chakra-ui/react";
import { useIdentity } from "@verified/sspa-core-lib";
import { Provider } from "react-redux";
import { ResultsPage } from "./pages/resultsPage/ResultsPage";
import { SearchPage } from "./pages/SearchPage/SearchPage";
import store from "./redux/store";
import { verified } from "./theme";

export function App() {
  const [session] = useIdentity(false);

  if (session === false) return <div>loading...</div>;
  if (!session?.user) return <div>authentication required.</div>;

  return (
    <Provider store={store}>
      <ChakraProvider theme={verified}>
        <Container maxW={"1240px"}>
          <SearchPage />
          <ResultsPage />
        </Container>
      </ChakraProvider>
    </Provider>
  );
}
