// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fD-3Shq3QaQmKwaX9is\\+lw\\=\\= {\n   background: #fff;\n   padding: 24px;\n   border-radius: 8px;\n   margin-top: 24px;\n}", "",{"version":3,"sources":["webpack://./src/pages/SearchPage/styles.module.css"],"names":[],"mappings":"AAAA;GACG,gBAAgB;GAChB,aAAa;GACb,kBAAkB;GAClB,gBAAgB;AACnB","sourcesContent":[".container {\n   background: #fff;\n   padding: 24px;\n   border-radius: 8px;\n   margin-top: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "fD-3Shq3QaQmKwaX9is+lw=="
};
export default ___CSS_LOADER_EXPORT___;
