import { createAsyncThunk } from "@reduxjs/toolkit";
import { http } from "../../services/http";
import { reduceEnvelopeDTO } from "../../services/envelopeReducer";

export const getEnvelopeById = createAsyncThunk(
  "getEnvelopeById",
  async (envelopeId: any, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`/api/envelopes/${envelopeId}`);

      if(!Array.isArray(data)) {
        return [data].map(reduceEnvelopeDTO);
      } 
      return data.map(reduceEnvelopeDTO);

    } catch (error: any) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getEnvelopeByName = createAsyncThunk(
  "getEnvelopeByName",
  async (envelopeId: string, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`/api/envelope/${envelopeId}`);

      return data;
    } catch (error: any) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
