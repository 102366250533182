import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "users",
  initialState: {
    loading: false,
    filters: undefined,
    results: undefined,
    error: null,
  },
  reducers: {},
});
