export enum FilterEntity {
  Envelope = "Envelope",
  // Company = "Company",
  // User = "User"
}


export enum EnvelopeFilterMethod {
  ById = "ById",
  ByEmail = "ByEmail"
}

export enum CompanyFilterMethod {
  ById = "ById",
  ByName = "ByName",
  ByNumber = "ByNumber",
}

export enum UserFilterMethod {
  ByEmail = "ByEmail",
  ByName = "ByName",
}

export const FilterMethodBasedOnFilterEntity = {
  [FilterEntity.Envelope]: Object.values(EnvelopeFilterMethod),
  // [FilterEntity.Company]: Object.values(CompanyFilterMethod),
  // [FilterEntity.User]: Object.values(UserFilterMethod),
};
