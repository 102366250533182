export const renderDateFormat = (dateString: string): string => {
  if (typeof dateString !== "string") {
    return "";
  }
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  try {
    const date = new Date(dateString);
    return (
      date.getDay() +
      " " +
      monthNames[date.getMonth()] +
      " " +
      date.getFullYear() +
      " " +
      date.getHours() +
      ":" +
      date.getMinutes()
    );
  } catch (error) {
    return "";
  }
};
