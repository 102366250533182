export const extractCompanyIdFromEnvelope = (envelope: any) => {
  const regex = /\/companies\/([\w-]{8,})/;
  const matches = envelope?.sender?.uid?.split("/users/")[0]?.match(regex);
  if (matches) {
    return matches[1];
  }
};

export const calculatePendingForState = (envelope: any, document: any) => {
  let recipients = envelope.recipients.filter(
    (recipient) => !recipient.completed
  );

  if (document.signatures?.length) {
    // Filter out the ones that already signed this document
    recipients = recipients.filter(
      (recipient) =>
        !document.signatures.find(
          (signature) =>
            recipient.id === signature.id ||
            recipient.uid ===
              (signature.signatory != null
                ? signature.signatory.uid
                : undefined)
        )
    );
  }

  recipients = recipients.sort((a, b) => a.order - b.order);
  if (envelope.sequentialSigning) {
    return recipients[0];
  } else {
    return recipients;
  }
};
const calculateDocumentStatus = (document, envelope) => {
  const docCopy = { ...document };

  if (docCopy.rejected) {
    const personWhoRejected =
      document.signatures[document.signatures.length - 1].familyName +
      " " +
      document.signatures[document.signatures.length - 1].givenName;
    docCopy.status = `Rejected by ${personWhoRejected}`;
    return docCopy;
  }
  if (docCopy.aborted) {
    docCopy.status = "Aborted";
    return docCopy;
  }

  if (docCopy.trashed) {
    docCopy.status = "Trashed";
    return docCopy;
  }

  if (docCopy.signed) {
    docCopy.status = "Signed by all recipients";
    return docCopy;
  }

  const pendingFor = calculatePendingForState(envelope, docCopy);

  let pendingForString = "";
  if (envelope.sequentialSigning) {
    pendingForString = pendingFor?.familyName + " " + pendingFor?.givenName;
  } else {
    pendingForString = pendingFor?.length + " recipients";
  }
  docCopy.status = "Pending for " + pendingForString;
  return docCopy;
};

export const signedThisDocument = (recipient, doc) => {
  if (!doc.signatures) {
    return false;
  }
  const signatureFound = doc.signatures.find(
    (signature) =>
      signature.id === recipient.id ||
      (signature.signatory != null ? signature.signatory.uid : undefined) ===
        recipient.uid
  );

  if (doc.signatures && doc.signatures.length) {
    return signatureFound;
  } else {
    return false;
  }
};

export const computeStatus = (envelope, document, recipient) => {
  // TODO WIP NOW
  // oneOf: sent, viewed, blocked, signed, approved, rejected, declinde
  let status = "unsent";
  // Calculate blocked based on document cancelled/aborted.

  if (
    envelope.aborted ||
    (envelope.expired && !recipient.completed) ||
    envelope.aborted
  ) {
    return "blocked";
  }

  if (envelope.rejected || document.rejected) {
    return "rejected";
  }

  if (document.signed || signedThisDocument(recipient, document)) {
    return "signed";
  }

  if (!recipient.hasOwnProperty("notifications")) {
    return status;
  }

  const { lastNotification } = recipient;
  for (const key in recipient.notifications) {
    if (key === lastNotification) {
      ({ status } = recipient.notifications[key]);
    }
  }

  return status;
};

export const calculateDocumentStatusPerRecipient = (envelope: any) => {
  const envCopy = JSON.parse(JSON.stringify(envelope));
  envCopy.recipients = envCopy.recipients.map((recipient) => {
    if (recipient.documentStatusMap == null) {
      recipient.documentStatusMap = {};
    }
    envCopy.documents.forEach((doc: any) => {
      recipient.documentStatusMap[doc.id] = {};

      if (recipient.documentStatusMap[doc.id].date == null) {
        recipient.documentStatusMap[doc.id].date = "";
      }

      recipient.documentStatusMap[doc.id].status = computeStatus(
        envelope,
        doc,
        recipient
      );

      const signatureFound = signedThisDocument(recipient, doc);

      if (signatureFound) {
        recipient.documentStatusMap[doc.id] = {
          date: signatureFound.date,
          comment: signatureFound.comment,
          status: signatureFound.status,
          givenName: signatureFound.givenName,
          familyName: signatureFound.familyName,
          hasSignatoryName: !!(
            signatureFound.givenName || signatureFound.familyName
          ),
        };
      }
    });
    return recipient;
  });
  return envCopy.recipients;
};
export const reduceEnvelopeDTO = (envelope: any) => {
  const envCopy = { ...envelope };
  if (!envCopy.published) {
    envCopy.documents = envCopy.documents.map((document) => {
      return { ...document, status: "DRAFT" };
    });
  } else {
    envCopy.documents = envelope.documents.map((document) =>
      calculateDocumentStatus(document, envelope)
    );
  }
  envCopy.recipients = calculateDocumentStatusPerRecipient(envCopy);

  const companyId = extractCompanyIdFromEnvelope(envCopy);
  envCopy.companyId = companyId;
  envCopy.redirectLink = `https://dev-verified.eu/#/archive?companyId=${companyId}`;
  return envCopy;
};
