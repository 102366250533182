export const fontSizes = {
  base: "14px",
  input: "14px",
  h1: "44px",
  h2: "26px",
  h3: "20px",
  h4: "18px",
  h5: "14px",
  h6: "12px",
};
