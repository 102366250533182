import singleSpaReact from "single-spa-react";
import React from "react";
import ReactDOMClient from "react-dom/client";
import { App } from "./App";

export const { bootstrap, mount, unmount } = singleSpaReact({
  React,
  ReactDOMClient,
  rootComponent: App,
  errorBoundary(err, info, props) {
    return <div>This renders when a catastrophic error occurs</div>;
  },
});
