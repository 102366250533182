// app/store.js
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { filterDataSlice, envelopeSlice } from "./reducers";

import type { PreloadedState } from "@reduxjs/toolkit";
import { companySlice } from "./reducers/companySlice";

// Create the root reducer separately so we can extract the RootState type
const rootReducer = combineReducers({
  envelope: envelopeSlice.reducer,
  filter: filterDataSlice.reducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];

const store = configureStore({
  reducer: {
    envelope: envelopeSlice.reducer,
    filter: filterDataSlice.reducer,
    company: companySlice.reducer,
  },
});

export default store;
