import { createSlice } from "@reduxjs/toolkit";
import { getEnvelopeById } from "../actions/envelopeActions";

export const initialEnvelopeState = {
  loading: false,
  error: null,
  result: null,
};

export const envelopeSlice = createSlice({
  name: "envelope",
  initialState: initialEnvelopeState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEnvelopeById.pending.toString(), (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getEnvelopeById.fulfilled.toString(),
      //@ts-ignore
      (state, { payload }) => {
        state.result = payload;
        state.loading = false;
      }
    );
    builder.addCase(
      getEnvelopeById.rejected.toString(),
      //@ts-ignore
      (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }
    );
  },
});
