import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const baseStyle = {
  borderWidth: "1px",
  borderStyle: "solid",
  fontWeight: "semibold",
  fontSize: "input",
  lineHeight: "1",
  px: "24px",
  height: "42px",
};

const solid = defineStyle({
  ...baseStyle,
  borderColor: "primary",
});

const outline = defineStyle({
  ...baseStyle,
});

const ghost = defineStyle({
  ...baseStyle,
  borderColor: "transparent",
});

export const buttonTheme = defineStyleConfig({
  variants: { solid, outline, ghost },
});
