import React, { useState } from "react";
import { useAppSelector } from "../../../redux/hooks";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Flex,
  Button,
  CircularProgress,
} from "@chakra-ui/react";
import { xor } from "lodash";
import { EnvelopeCard } from "./EnvelopeCard";

export const EnvelopeResultsPage = () => {
  const envelopeState = useAppSelector((state) => state.envelope);

  if (envelopeState.error) {
    return (
      <Alert my={4} status="error">
        <AlertIcon />
        <AlertTitle>We could not filter the data.</AlertTitle>
        <AlertDescription>{envelopeState.error}</AlertDescription>
      </Alert>
    );
  }

  if (envelopeState.loading) {
    return (
      <CircularProgress
        data-testid="loader"
        isIndeterminate
        value={30}
        size="100px"
      />
    );
  }

  if (envelopeState.result) {
    const envelopes: Array<any> = envelopeState.result;

    return (
      <Box mt={"26px"}>
        <Flex justifyContent={"space-between"} mb={"24px"}>
          Found {envelopes.length} envelope/s:{" "}
        </Flex>
        {envelopes.map((envelope: any, index) => (
          <EnvelopeCard key={envelope.id} envelope={envelope} />
        ))}
      </Box>
    );
  }

  return <></>;
};
