import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Link,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { xor } from "lodash";
import { useState } from "react";
import { renderDateFormat } from "../../../utils/renderDate";

import styles from "./styles.module.css";

export const EnvelopeCard = ({ envelope }: { envelope: any }) => {
  const [openIndexes, setOpenIndexes] = useState<Array<number>>([]);

  const { documents } = envelope;

  const collapseAllItems = () => {
    setOpenIndexes([]);
  };

  const expandAllItems = () => {
    const allIndexesArray = envelope.documents.map((el, index) => index);
    setOpenIndexes(allIndexesArray);
  };

  const handleAccordionItemClick = (index: number) => {
    const openIndexesCopy = xor(openIndexes, [index]);
    setOpenIndexes([...openIndexesCopy]);
  };
  return (
    <Box mb={"24px"} borderRadius={"8px"}>
      <Flex
        justifyContent={"space-between"}
        marginBottom={"4"}
        alignItems={"center"}
      >
        <Box>Company Id: {envelope.companyId} </Box>
        {documents?.length > 1 && (
          <Box>
            <Button onClick={collapseAllItems} mr={"16px"}>
              Collapse all documents
            </Button>
            <Button onClick={expandAllItems}>Expand all documents</Button>{" "}
          </Box>
        )}
      </Flex>

      <Accordion index={openIndexes} data-testid="envelope">
        {documents.map((document: any, index) => {
          return (
            <AccordionItem
              key={document.id}
              onClick={() => handleAccordionItemClick(index)}
              marginBottom={"0px"}
            >
              <AccordionButton width={"100%"}>
                <Flex width={"100%"} justifyContent={"space-between"}>
                  <Box>
                    <Flex direction={"column"} alignItems={"flex-start"}>
                      <Box>{document.name}</Box>
                      <span
                        className={
                          styles[document.status?.split(" ")[0]?.toLowerCase()]
                        }
                      >
                        {document.status}
                      </span>
                    </Flex>
                  </Box>
                  <Link href={envelope.redirectLink} isExternal>
                    See envelope in archive{" "}
                    <ExternalLinkIcon mr="16px" ml="4px" />
                  </Link>
                </Flex>

                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <Flex>
                  ID: #{envelope.id} | Created{" "}
                  {renderDateFormat(envelope.publishDate || envelope.created)}{" "}
                  by {envelope.sender.familyName} {envelope.sender.givenName}
                </Flex>
                <Box>Greeting: {envelope.greeting}</Box>
                <TableContainer marginTop={"8px"}>
                  <Table size="sm">
                    <Thead>
                      <Tr>
                        <Th>Name</Th>
                        <Th>Status</Th>
                        <Th>Email</Th>
                        <Th>Signing method</Th>
                        <Th>Role</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {envelope.recipients.map((recipient: any) => (
                        <Tr key={recipient.id}>
                          <Td>
                            {recipient.familyName} {recipient.givenName}
                          </Td>
                          <Td>
                            {recipient.documentStatusMap &&
                              recipient.documentStatusMap[document.id].status}
                          </Td>
                          <Td>{recipient.email}</Td>
                          <Td>{recipient.signingMethod}</Td>
                          <Td>{recipient.role?.name}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </AccordionPanel>
            </AccordionItem>
          );
        })}
      </Accordion>
    </Box>
  );
};
