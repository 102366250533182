export const colors = {
  transparent: "transparent",
  white: "#FFF",
  primary: {
    50: "#EEF7FB",
    75: "#DCF0FA",
    100: "#B6D5E9",
    300: "#64AEDB",
    500: "#218BCB",
    700: "#1478B4",
    900: "#065D90",
  },
  body: {
    50: "#F4F4F4",
    75: "#E1E1E1",
    100: "#D5D5D5",
    300: "#999999",
    500: "#444444",
    700: "#222222",
    900: "#000000",
  },
  danger: {
    50: "#FAE6E6",
    75: "#F2C4C4",
    100: "#F2C4C4",
    300: "#CC6673",
    500: "#B62537",
    700: "#9F2A39",
    900: "#84212E",
  },
  warning: {
    50: "#F4E7C3",
    75: "#EAD292",
    100: "#EAD292",
    300: "#F2D484",
    500: "#EDC250",
    700: "#CCA94C",
    900: "#AB8C3A",
  },
};
