import { getEnvelopeById } from "../../redux/actions/envelopeActions";
import { getCompanies } from "../../redux/actions/companyActions";

import {
  CompanyFilterMethod,
  EnvelopeFilterMethod,
  FilterEntity,
  UserFilterMethod,
} from "../../types";

export const filterActions = {
  [FilterEntity.Envelope]: {
    [EnvelopeFilterMethod.ById]: getEnvelopeById,
    [EnvelopeFilterMethod.ByEmail]: getEnvelopeById,
  },
  // [FilterEntity.Company]: {
  //   [CompanyFilterMethod.ById]: getCompanies,
  //   [CompanyFilterMethod.ByName]: getCompanies,
  //   [CompanyFilterMethod.ByNumber]: getCompanies,
  // },
  // [FilterEntity.User]: {
  //   [UserFilterMethod.ByEmail]: "",
  //   [UserFilterMethod.ByName]: "",
  // },
};
