import { createSlice } from "@reduxjs/toolkit";
import { getCompanies } from "../actions/companyActions";

export const initialCompanyState = {
  loading: false,
  error: null,
  result: null,
};

export const companySlice = createSlice({
  name: "company",
  initialState: initialCompanyState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCompanies.pending.toString(), (state, action) => {
      state.loading = true;
      state.error = null;
    });
    //@ts-ignore
    builder.addCase(getCompanies.fulfilled.toString(), (state, { payload }) => {
      state.result = payload;
      state.loading = false;
    });
    //@ts-ignore
    builder.addCase(getCompanies.rejected.toString(), (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
  },
});
