import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { EnvelopeFilterMethod, FilterEntity } from "../../types";
import { queryData } from "../actions/filterActions";

export const filterInitialState = {
  filterEntity: FilterEntity.Envelope,
  filterMethod: EnvelopeFilterMethod.ById,
  filterValue: "",
  loading: false,
  error: null,
  result: null,
};

export const filterDataSlice = createSlice({
  name: "filterData",
  initialState: filterInitialState,
  reducers: {
    setFilterData: (state, { payload }) => {
      state.filterEntity = payload.filterEntity;
      state.filterMethod = payload.filterMethod;
      state.filterValue = payload.filterValue;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(queryData.pending.toString(), (state, {  }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(queryData.fulfilled.toString(), (state, {  }) => {
      state.loading = false;
    });
    //@ts-ignore
    builder.addCase(queryData.rejected.toString(), (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
  },
});

export const { setFilterData } = filterDataSlice.actions;
